import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import Background from "../images/bg-our-way-mobile.png";

export default function OurWay(props) {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Wrapper bg={props.data.ourWayBg.sourceUrl} bgMobile={Background}>
      <div className={window.innerWidth > 768 ? "container" : "h-100"}>
        <div className="p-sm">
          <p className="title">{props.data.ourWayTitle}</p>
          <p className="text">{props.data.ourWayText}</p>
        </div>
        <CardWrapper>
          {window.innerWidth >= 768 ? (
            props.data.cards.map((card, i) => (
              <Card key={i}>
                <img src={card.cardIcon.sourceUrl} alt="Card Icon" />
                <p className="card-title">{card.cardTitle}</p>
                <p className="card-text">{card.cardText}</p>
              </Card>
            ))
          ) : (
            <Slider {...settings} style={{ marginTop: "32px" }}>
              {props.data.cards.map((card, i) => (
                <div key={i} className="h-100">
                  <CardMobile>
                    <div>
                      <img src={card.cardIcon.sourceUrl} alt="Card Icon" />
                      <p className="card-title">{card.cardTitle}</p>
                      <p className="card-text">{card.cardText}</p>
                    </div>
                  </CardMobile>
                </div>
              ))}
            </Slider>
          )}
        </CardWrapper>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: url(${(props) => props.bg}) no-repeat top right;
  background-size: contain;
  padding: 80px 100px;

  .title {
    font-family: Comfortaa;
    font-weight: bold;
    font-size: 34px;
    color: #004b8f;
  }

  .text {
    font-family: Nunito;
    color: #636872;
  }

  @media (width: 1024px) {
    .text {
      max-width: 500px;
    }
  }

  @media (max-width: 768px) {
    & {
      background: #ffff url(${(props) => props.bgMobile}) no-repeat bottom;
      padding: 50px 30px;

      .title {
        font-size: 24px;
      }
    }
  }

  @media (max-width: 450px) {
    height: 655px;
    padding: 0;
    .p-sm {
      padding: 50px 30px 0;
    }
  }

  @media (max-width: 360px) {
    & {
      height: 685px;
    }
  }

  .slick-dots {
    li {
      button {
        width: 10px;
        height: 10px;
        padding: 0;
        border: 1px solid #004b8f;
        background: transparent;
        margin: auto;
        border-radius: 100%;

        &:before {
          opacity: 0;
        }
      }

      &.slick-active {
        button:before {
          opacity: 1;
          color: #004b8f;
          font-size: 11px;
          line-height: 11px;
        }
      }
    }
  }

  .slick-track {
    display: flex;

    .slick-slide {
      display: flex;
      height: auto;
      padding: 10px 0;
      align-items: center;
      justify-content: center;

      > div {
        height: 100%;
      }
    }
  }
`;

const CardWrapper = styled.div`
  @media (min-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 870px;
  }

  @media (max-width: 450px) {
    .slick-slider,
    .slick-list,
    & {
      height: 100%;
    }

    .slick-dots {
      bottom: 235px;
    }
  }
`;

const Card = styled.div`
  max-width: 270px;
  background: #ffffff;
  border-radius: 24px 24px 0px 0px;
  padding: 56px 25px;
  color: #636872;
  margin-bottom: 32px;
  box-shadow: 0px 65px 41px rgba(0, 0, 0, 0.07),
    0px 27.1554px 17.1288px rgba(0, 0, 0, 0.0503198),
    0px 14.5186px 9.15789px rgba(0, 0, 0, 0.0417275),
    0px 8.13901px 5.13384px rgba(0, 0, 0, 0.035),
    0px 4.32257px 2.72654px rgba(0, 0, 0, 0.0282725),
    0px 1.79872px 1.13458px rgba(0, 0, 0, 0.0196802);
  // box-shadow: 2px 1px 10px 2px rgba(0, 0, 0, 0.31);

  .card-title {
    font-family: Comfortaa;
    font-weight: bold;
    font-size: 20px;
    margin-top: 32px;
  }

  .card-text {
  }
`;

const CardMobile = styled.div`
  padding: 56px 24px 30px;
  background: white;
  max-width: 270px;
  height: 100%;
  box-shadow: 0px 65px 41px rgba(0, 0, 0, 0.07),
    0px 27.1554px 17.1288px rgba(0, 0, 0, 0.0503198),
    0px 14.5186px 9.15789px rgba(0, 0, 0, 0.0417275),
    0px 8.13901px 5.13384px rgba(0, 0, 0, 0.035),
    0px 4.32257px 2.72654px rgba(0, 0, 0, 0.0282725),
    0px 1.79872px 1.13458px rgba(0, 0, 0, 0.0196802);

  img {
    margin-bottom: 32px;
  }

  .card-title {
    font-family: Comfortaa;
    font-weight: bold;
    font-size: 20px;
    color: #636872;
  }

  .card-text {
    font-family: Nunito;
    color: #636872;
  }
`;
