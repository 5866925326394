import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Logo from "../images/logo-footer.png";
import Mail from "../images/mail.png";
import Instagram from "../images/instagram.png";
import Arquivo from "../doc/Equidade_Salarial_BCC.pdf";

export default function Footer() {
  return (
    <FooterWrapper className="container">
      <div className="icons-wrapper">
        <div>
          <Link to="/">
            <img src={Logo} alt="Brasil Center" />
          </Link>
        </div>
        <div>
          <a href="mailto:FALECOMABCC@brasilcenter.com.br"><img className="mail" src={Mail} alt="Mail icon" /></a>
          <a href="https://instagram.com/brasilcenter_oficial" target="_blank"><img className="instagram" src={Instagram} alt="Instagram icon" /></a>
        </div>
      </div>
      <div css="text-align:center;">
      <div>
      <p>
        <b>
        <a href="https://www.claro.com.br/politica-de-privacidade?site=brasilcenter" target="_blank">Politica de Privacidade</a></b>
      </p>
      </div>
      <div>  
      <p>
        <b>
        <a href="https://denuncias.americamovil.com/" target="_blank">Portal de Denúncias</a></b>
      </p>
      </div>
      <div>    
      <p>
        <b>
        <a href="https://s22.q4cdn.com/604986553/files/doc_downloads/code_ethics/2019/10/C%C3%B3digo-de-%C3%A9tica-portugu%C3%A9s-(versi%C3%B3n-25-octubre-2019)-(1).pdf" target="_blank">Código de Ética da AMX</a></b>
      </p> 
      </div>
      <div>    
      <p>
        <b>
        <a href={Arquivo} target="_blank" type="application/pdf" rel="alternate" media="print">Transparência Salarial</a></b>
      </p> 
      </div>
      </div>
                    
      <p>
        <b>BrasilCenter © 2024 {window.innerWidth > 450 && "-"}</b>
        {window.innerWidth < 450 && <br />} Todos os direitos reservados
      </p>
    </FooterWrapper>
  );
}

const FooterWrapper = styled.footer`
  padding: 44px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .icons-wrapper {
    display: flex;
    align-items: flex-end;

    .mail {
      margin-left: 24px;
      width: 24px;
    }

    .instagram {
      margin-left: 24px;
      width: 24px;
    }
  }

  p {
    font-family: Nunito;
    font-size: 16px;
    color: #636872;
    margin: 0;
  }

  @media (max-width: 768px) {
    padding: 44px 80px;
    display: block;

    p {
      text-align: center;
    }

    .icons-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .mail {
        margin-left: 0;
        margin-top: 32px;
        margin-bottom: 24px;
      }

      .instagram {
        margin-left: 0;
        margin-top: 32px;
        margin-bottom: 24px;
      }
    }
  }
`;
