import React, { useEffect, useState } from "react";
import styled from "styled-components";

export default function Dropdown(props) {
  const [dropDrownItems, setDropdownItems] = useState([]);
  const [active, setActive] = useState(false);
  const setActiveItem = (e) => {
    if (props.content === "locations") {
      props.setSelectedLocation(e.target.innerText);
      setActive(false);
    } else {
      props.setActivePin(e.target.innerText.toLowerCase());
      setActive(false);
    }
  };

  const filteredLocations = (locations) => {
    let filteredLocations;
    if (props.content === "locations") {
      let allLocations = locations.map((location) => location.estado);
      filteredLocations = allLocations.filter(
        (val, i, self) => self.indexOf(val) === i
      );
    } else {
      filteredLocations = props.items;
    }
    setDropdownItems(filteredLocations);
    return filteredLocations;
  };

  useEffect(() => {
    filteredLocations(props.items);
  }, []);

  return (
    <Wrapper>
      <div
        className="d-flex justify-content-center align-items-center"
        onClick={() => setActive(!active)}
      >
        <p className="m-0">BUSCAR EM UMA LOCALIDADE</p>
        <div style={{ marginLeft: "15px" }}>
          <i className={`arrow ${active ? "up" : "down"}`}></i>
        </div>
      </div>
      <div className={`list-items ${active && "active"}`}>
        {dropDrownItems.length > 0 &&
          dropDrownItems.map((item, i) => (
            <p key={i} onClick={(e) => setActiveItem(e)}>
              {item}
            </p>
          ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: white;
  border-radius: 44px;
  padding: 18px 25px;
  text-align: center;
  margin-bottom: 32px;

  .arrow {
    border: solid #004b8f;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .list-items {
    max-height: 0;
    transition: max-height 0.5s ease-out;
    overflow: hidden;

    &.active {
      max-height: 500px;
      transition: max-height 0.5s ease-in;
    }
  }

  P {
    font-family: Nunito;
    font-weight: 800;
    font-size: 14px;
    color: #004b8f;
    text-transform: uppercase;
    margin: 10px 0;
  }

  @media (width: 320px) {
    p {
      font-size: 12px;
    }
  }
`;
