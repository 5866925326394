import React, { useState, useEffect } from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import styled from "styled-components";

import Header from "../components/Header";
import Banner from "../components/Banner";
import Button from "../components/Button";
import Dropdown from "../components/Dropdown";
import JoinUs from "../components/JoinUs";

import BgMap from "../images/bg-map-locations.png";
import BgMapMobile from "../images/bg-map-mobile.png";
import Line from "../images/line.png";

export default function OndeEstamos() {
  const [selectedLocation, setSelectedLocation] = useState();
  const MyQuery = gql`
    query MyQuery {
      page(id: "cGFnZTo5") {
        ondeEstamos {
          banner {
            title
            text
            imageDesk {
              sourceUrl
            }
            imageMobile {
              sourceUrl
            }
          }
          section {
            backgroundImage {
              sourceUrl
            }
            backgroundImageMobile {
              sourceUrl
            }
            title
            text
            blueText
          }
          offices {
            title
            text
            locations {
              estado
              locationName
              streetLocation
              linkMaps
            }
          }
          joinUs {
            backgroundDesk {
              sourceUrl
            }
            backgroundMobile {
              sourceUrl
            }
            buttonLink
            buttonText
            textLines {
              lineText
            }
          }
        }
      }
      components {
        nodes {
          Map {
            offices {
              adress
              cityName
              fieldGroupName
              homeOffice
              jobs
              linkMap
              linkRegister
              pcd
              state
            }
          }
        }
      }
    }
  `;

  const renderLocation = (uf, estado, AllLocations) => {
    const filteredLocations = AllLocations.filter(
      (location) => location.estado === uf
    );
    return (
      <div id={uf} className="location">
        {<p className="estado">{estado}</p>}
        {filteredLocations.map((filteredLocation, i) => (
          <div key={`item-${i}`} style={{ marginBottom: "44px" }}>
            <p className="name">{filteredLocation.locationName}</p>
            <p className="street">{filteredLocation.streetLocation}</p>
            {/* <Button text="ver no mapa" link={filteredLocation.linkMap} /> */}
          </div>
        ))}
      </div>
    );
  };

  const fullLocationName = (location) => {
    switch (location) {
      case "RJ":
        return "Rio de Janeiro";
        break;
      case "BA":
        return "Bahia";
        break;
      case "PE":
        return "Pernambuco";
        break;
      case "GO":
        return "Goiás";
        break;
      case "ES":
        return "Espirito Santo";
        break;
      case "MG":
        return "Minas Gerais";
        break;
      case "SP":
        return "São Paulo";
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (selectedLocation && window.innerWidth <= 768) {
      let allContainers = document.querySelectorAll(".location");
      let selectedContainer = document.getElementById(selectedLocation);
      [...allContainers].map((container) => (container.style.display = "none"));
      selectedContainer.style.display = "block";
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      let allContainers = document.querySelectorAll(".location");
      if (allContainers.length > 0) {
        [...allContainers].map(
          (container) => (container.style.display = "none")
        );
        allContainers.item(0).style.display = "block";
      }
    }
  }, []);

  return (
    <Query query={MyQuery}>
      {({ loading, error, data }) => {
        if (loading)
          return (
            <div
              style={{
                background: "#004B8F",
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
              }}
            >
              <p>Loading...</p>
            </div>
          );
        if (error) return <div>Error :(</div>;
          console.log(data.page.ondeEstamos.offices.locations);
          console.log(data.components.nodes[0].Map.offices);
        return (
          <>
            <Header />
            <Banner infos={data.page.ondeEstamos} static />
            <Section
              bg={data.page.ondeEstamos.section.backgroundImage.sourceUrl}
              bgMobile={
                data.page.ondeEstamos.section.backgroundImageMobile.sourceUrl
              }
            >
              <div className="content">
                <p className="title">{data.page.ondeEstamos.section.title}</p>
                <p className="text">{data.page.ondeEstamos.section.text}</p>
                <p className="blue-text">
                  {data.page.ondeEstamos.section.blueText}
                </p>
              </div>
              {window.innerWidth < 768 && (
                <div>
                  <img
                    src={
                      data.page.ondeEstamos.section.backgroundImageMobile
                        .sourceUrl
                    }
                    alt="background"
                  />
                </div>
              )}
            </Section>
            <Locations bg={BgMap} bgMobile={BgMapMobile}>
              <div className="container">
                <p className="title">{data.page.ondeEstamos.offices.title}</p>
                <p className="text">{data.page.ondeEstamos.offices.text}</p>
                {window.innerWidth <= 768 && (
                  <div>
                    <Dropdown
                      items={data.page.ondeEstamos.offices.locations}
                      setSelectedLocation={setSelectedLocation}
                      content="locations"
                    />
                    <img className="w-100" src={Line} alt="Line detail" />
                  </div>
                )}
                {window.innerWidth > 768 ? (
                  <div className="row" style={{ marginTop: "72px" }}>
                    <div className="col-lg-4">
                      {renderLocation(
                        "RJ",
                        "Rio de Janeiro",
                        data.page.ondeEstamos.offices.locations
                      )}
                      {renderLocation(
                        "BA",
                        "Bahia",
                        data.page.ondeEstamos.offices.locations
                      )}
                      {renderLocation(
                        "PE",
                        "Pernambuco",
                        data.page.ondeEstamos.offices.locations
                      )}
                    </div>
                    <div className="col-lg-4">
                      {renderLocation(
                        "GO",
                        "Goiás",
                        data.page.ondeEstamos.offices.locations
                      )}
                      {renderLocation(
                        "ES",
                        "Espírito Santo",
                        data.page.ondeEstamos.offices.locations
                      )}
                      {renderLocation(
                        "MG",
                        "Minas Gerais",
                        data.page.ondeEstamos.offices.locations
                      )}
                    </div>
                    <div className="col-lg-4">
                      {renderLocation(
                        "SP",
                        "São Paulo",
                        data.page.ondeEstamos.offices.locations
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="row" style={{ marginTop: "72px" }}>
                    <div className="col-lg-12">
                      {selectedLocation
                        ? renderLocation(
                            selectedLocation,
                            data.page.ondeEstamos.offices.locations
                          )
                        : renderLocation(
                            "RJ",
                            "Rio de Janeiro",
                            data.page.ondeEstamos.offices.locations
                          )}
                    </div>
                  </div>
                )}
              </div>
            </Locations>
            <JoinUs data={data.page.ondeEstamos.joinUs} />
          </>
        );
      }}
    </Query>
  );
}

const Section = styled.div`
  background: url(${(props) => props.bg}) no-repeat;
  background-size: cover;
  height: 39.75vw;

  .content {
    width: 50%;
    height: 100%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 7.24vw;

    .title {
      font-family: Comfortaa;
      font-weight: bold;
      font-size: 34px;
      color: #a9b531;
    }

    .text,
    .blue-text {
      font-family: Nunito;
    }

    .text {
      color: #636872;
    }

    .blue-text {
      color: #004b8f;
      font-weight: bold;
    }
  }

  @media (max-width: 768px) {
    background: white;
    height: auto;

    .content {
      width: 100%;
      padding: 30px 44px 0;
      justify-content: start;
    }

    img {
      width: 100%;
    }
  }
`;

const Locations = styled.div`
  background: #014486 url(${(props) => props.bg}) no-repeat;
  background-size: contain;
  background-position: center;
  padding: 91px 98px;

  .title {
    font-family: Comfortaa;
    font-weight: bold;
    font-size: 34px;
    color: #a9b531;
  }

  .text {
    font-family: Nunito;
    color: white;
  }

  .location {
    max-width: 330px;

    .estado {
      font-family: Comfortaa;
      font-weight: bold;
      font-size: 24px;
      color: #a9b531;
    }
    .name {
      font-family: Nunito;
      font-weight: bold;
      font-size: 18px;
      color: #ffffff;
      line-height: 24px;
      margin-bottom: 8px;
    }
    .street {
      font-family: Nunito;
      color: #ffffff;
    }
  }

  @media (max-width: 450px) {
    position: static;
    background: #014486 url(${(props) => props.bgMobile}) no-repeat;
    background-size: 100% 1500px;
    background-position: top;
    padding: 44px 30px !important;
  }
`;
