import React from "react";
import styled from "styled-components";
import Button from "../components/Button";
import { Link } from "react-router-dom";

export default function JoinUs(props) {
  let isPageOndeEstamos = window.location.pathname.includes("onde-estamos");
  let bgImg = props.data.backgroundDesk.sourceUrl;
  let bgImgMobile = props.data.backgroundMobile.sourceUrl;
  return (
    <JoinUsWrapper bg={window.innerWidth > 768 ? bgImg : bgImgMobile}>
      <div className="container px-0">
        <div>
          {props.page === "home" ? (
            <p>
              Venha trabalhar com a gente e seja{" "}
              <br className={window.innerWidth > 450 ? "d-block" : "d-none"} />
              protagonista da sua própria história de sucesso.
            </p>
          ) : (
            props.data.textLines.map((line, i) => (
              <p className={i === 0 && window.innerWidth < 768 && "mb-0"}>
                {line.lineText}
              </p>
            ))
          )}
        </div>
        <a href={props.data.buttonLink}>
          <Button text={props.data.buttonText} page={props.page} />
        </a>
      </div>
    </JoinUsWrapper>
  );
}

const JoinUsWrapper = styled.div`
  background: url(${(props) => props.bg}) no-repeat;
  background-size: cover;
  padding: 68px 0;
  height: auto;

  .container,
  .row {
    height: 100%;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  p {
    font-family: Comfortaa;
    font-weight: bold;
    font-size: 24px;
    color: #ffffff;
    margin: 0;
  }

  @media (max-width: 768px) {
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        text-align: center;
        margin-bottom: 24px;
        font-size: 20px;
      }
    }
  }

  @media (max-width: 450px) {
    background-size: 100% 100%;
    .container {
      padding: 0 8vw;
    }
  }
`;
