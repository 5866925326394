import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Router, Link, useLocation } from "react-router-dom";

import Logo from "../images/brasil-center-logo.png";
import LogoMobile from "../images/brasil-center-logo-mobile.png";
import Hamburguer from "../images/menu.png";
import BgMobile from "../images/bg-mobile.png";
import Close from "../images/close.png";
import MiniLogo from "../images/mini-logo.png";
import Line from "../images/line.png";
import Mail from "../images/mail.png";

export default function Header() {
  const [showMenu, SetShowMenu] = useState(false);
  let location = useLocation();

  const setBtnBgColor = (currentPath) => {
    let currentUrl = location.pathname.substring(1, location.pathname.length);
    switch (currentUrl) {
      case "":
        return "#004B8F";
        break;

      case "quem-somos":
        return "#0FA752";
        break;

      case "onde-estamos":
        return "#004B8F";
        break;

      case "trabalhe-conosco":
        return "#A9B531";
        break;

      default:
        break;
    }
  };

  return (
    <HeaderWrapper>
      {window.innerWidth > 1024 ? (
        <MenuDesk>
          <div>
            <Link to="/">
              <img src={Logo} alt="Brasil Center" />
            </Link>
          </div>
          <MenuItems>
            <Link to="/">
              <p>Início</p>
            </Link>
            <Link to="/quem-somos">
              <p>Quem Somos</p>
            </Link>
            <Link to="/onde-estamos">
              <p>Onde Estamos</p>
            </Link>
            <Link to="/trabalhe-conosco">
              <JoinUsBtn bgColor={setBtnBgColor()}>Junte-se a nós</JoinUsBtn>
            </Link>
          </MenuItems>
        </MenuDesk>
      ) : (
        <div>
          <div style={{ display: "flex" }}>
            <div
              onClick={() => SetShowMenu(true)}
              style={{ marginRight: "50px" }}
            >
              <img src={Hamburguer} alt="Hamburguer menu icon" />
            </div>
            <div>
              <img src={LogoMobile} alt="Brasil Center" />
            </div>
          </div>
          <MenuMobile showMenu={showMenu}>
            <div>
              <CloseMenuIcon
                src={Close}
                alt="Close modal icon"
                onClick={() => SetShowMenu(false)}
              />
              <img src={MiniLogo} alt="Brasil Center" />
              <Link to="/" onClick={() => SetShowMenu(false)}>
                <p>Página Inicial</p>
              </Link>
              <Link to="/quem-somos" onClick={() => SetShowMenu(false)}>
                <p>Quem Somos</p>
              </Link>
              <Link to="/onde-estamos" onClick={() => SetShowMenu(false)}>
                <p>Onde Estamos</p>
              </Link>
              <Link to="/trabalhe-conosco" onClick={() => SetShowMenu(false)}>
                <p>Junte-se a nós</p>
              </Link>
              <img src={Line} alt="Line detail" />
              <a href="mailto:FALECOMABCC@brasilcenter.com.br"><img src={Mail} style={{ display: "block" }} alt="Mail icon" /></a>
            </div>
          </MenuMobile>
        </div>
      )}
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.header`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding: 35px 95px 0 99px;

  @media (max-width: 450px) {
    padding: 16px 16px 0;
  }
`;

const MenuDesk = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const MenuItems = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 36px;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    font-family: Nunito;
    margin-bottom: 0;
  }
`;

const JoinUsBtn = styled.button`
  background: ${(props) => props.bgColor};
  border-radius: 44px;
  border: none;
  color: #ffffff;
  padding: 7.5px 16px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  margin-left: 26px;
`;

const MenuMobile = styled.div`
  background: url(${BgMobile}) no-repeat top center;
  background-size: cover;
  width: 100%;
  position: absolute;
  top: 0;
  left: ${(props) => (props.showMenu === true ? `0` : `-100%`)};
  height: 100vh;
  transition: left 1s;
  div {
    padding-top: 86px;
    padding-left: 30px;
  }

  p {
    font-family: Nunito;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
  }

  p,
  img {
    margin-bottom: 44px;
  }
`;

const CloseMenuIcon = styled.img`
  position: absolute;
  right: 21px;
  top 22px;
`;
