import React from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import styled from "styled-components";

import Header from "../components/Header";
import Banner from "../components/Banner";
import AwardsSection from "../components/AwardsSection";
import OurWay from "../components/OurWay";
import Map from "../components/Map";
import JoinUs from "../components/JoinUs";

import CloudWords from "../images/nuvem-de-palavras.png";
import CloudWordsMobile from "../images/nuvem-de-palavras-mobile.png";

export default function Home() {
  const MyQuery = gql`
    query MyQuery {
      page(id: "cGFnZToxMTE") {
        home {
          banner {
            button
            text
            title
            link
            image {
              mediaItemUrl
              sourceUrl
            }
            imageMobile {
              sourceUrl
            }
          }
          findOut {
            button
            text
            title
            link
            backgroundImage {
              sourceUrl
            }
            backgroundImageMobile {
              sourceUrl
            }
            video
          }
          ourWay {
            ourWayTitle
            ourWayText
            cards {
              cardText
              cardTitle
              cardIcon {
                sourceUrl
              }
            }
            ourWayBg {
              sourceUrl
            }
          }
          joinUs {
            backgroundDesk {
              sourceUrl
            }
            backgroundMobile {
              sourceUrl
            }
            buttonLink
            buttonText
            textLines {
              lineText
            }
          }
        }
      }
    }
  `;
  return (
    <>
      <Query query={MyQuery}>
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div
                style={{
                  background: "#004B8F",
                  height: "100vh",
                  width: "100vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                }}
              >
                <p>Loading...</p>
              </div>
            );
          if (error) return <div>Error :(</div>;

          return (
            <>
              <Header />
              <Banner data={data.page.home.banner} />
              <Section
                // bg={data.page.home.findOut.backgroundImage.sourceUrl}
                // bgMobile={
                //   data.page.home.findOut.backgroundImageMobile.sourceUrl
                // }
              >
                <div className="container">
                  <div className="row">
                    {/* <div className="content offset-lg-6 col-lg-6 col-sm-12"> */}
                    <div className="content col-md-12">
                      <div className="pl-lg col-md-6">
                        <iframe width="100%" height="315" src={data.page.home.findOut.video} title="Brasil Center" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>
                      <div className="pl-lg col-md-6">
                        <p className="title">{data.page.home.findOut.title}</p>
                        <p className="text">{data.page.home.findOut.text}</p>
                        <a href={data.page.home.findOut.link}>
                          <button>{data.page.home.findOut.button}</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Section>
              <AwardsSection />
              <OurWay data={data.page.home.ourWay} />
              <Map />
              <CloudWordsSection
                bg={window.innerWidth > 768 ? CloudWords : CloudWordsMobile}
              ></CloudWordsSection>
              <JoinUs data={data.page.home.joinUs} page="home" />
            </>
          );
        }}
      </Query>
    </>
  );
}

const Section = styled.div`
  background: url(${(props) => props.bg}) no-repeat;
  background-size: cover;
  height: 495px;

  .container {
    height: 100%;
  }

  @media (max-width: 767px) {
    .content {
      flex-direction: column!important;
      margin: -30px auto!important;
    }
  }

  @media (min-width: 768px) {
    .content {
      flex-direction: row;
      margin: 80px auto;
    }
    .pl-lg {
      // padding-left: 50px;
      margin-left: 40px;
    }
  }

  @media (max-width: 768px) {
    background: url(${(props) => props.bgMobile}) no-repeat;
    background-size: cover;
    background-position: bottom;
    height: 700px;

    .content {
      justify-content: start !important;
      padding: 44px 30px 0;

      .title {
        font-size: 24px !important;
      }
    }
  }

  @media (width: 320px) {
    background-size: contain;
  }

  @media (width: 768px) {
    background-size: contain;
  }

  @media (width: 1024px) {
    background-position: center;
  }

  .content,
  .row {
    height: 100%;
  }

  .content {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: row;
    margin: 80px auto;

    .title {
      font-family: Comfortaa;
      font-size: 34px;
      font-weight: bold;
      color: #004b8f;
    }

    .text {
      font-family: Nunito;
      color: #636872;
    }

    button {
      background: transparent;
      border: 1px solid #a9b531;
      border-radius: 44px;
      padding: 12.5px 24px;
      color: #a9b531;
      font-family: Nunito;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
`;

const CloudWordsSection = styled.div`
  background: url(${(props) => props.bg}) no-repeat;
  background-size: contain;
  background-position: center;
  height: 559px;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 768px) {
    height: 609px;
  }
`;
