import React from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import styled from "styled-components";
import Slider from "react-slick";

import Header from "../components/Header";
import Banner from "../components/Banner";
import JoinUs from "../components/JoinUs";
import Ellipse from "../images/ellipse.png";
import Award from "../images/award.png";
import Line from "../images/line-award.png";

export default function QuemSomos(props) {
  const MyQuery = gql`
    query MyQuery {
      page(id: "cGFnZTo3") {
        quemSomos {
          banner {
            text
            title
            imageMobile {
              sourceUrl
            }
            imageDesk {
              sourceUrl
            }
          }
          section {
            backgroundImage {
              sourceUrl
            }
            text
            title
          }
          carousel {
            icon {
              sourceUrl
            }
            text
            title
          }
          history {
            title
            text
            carousel {
              year
              text
              image {
                sourceUrl
              }
            }
          }
          achievements {
            title
            text
            carousel {
              title
              text
              awards
            }
          }
          joinUs {
            backgroundDesk {
              sourceUrl
            }
            backgroundMobile {
              sourceUrl
            }
            buttonLink
            buttonText
            textLines {
              lineText
            }
          }
        }
      }
    }
  `;
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const sliderTimeLIne = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const sliderAchievments = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Query query={MyQuery}>
      {({ loading, error, data }) => {
        if (loading)
          return (
            <div
              style={{
                background: "#004B8F",
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
              }}
            >
              <p>Loading...</p>
            </div>
          );
        if (error) return <div>Error :(</div>;

        return (
          <>
            <Header />
            <Banner infos={data.page.quemSomos} static />
            <Section>
              <div
                className={`d-flex position-relative ${
                  window.innerWidth === 1920 && "container"
                }`}
              >
                <div className="content-wrapper">
                  <h2>{data.page.quemSomos.section.title}</h2>
                  <p>{data.page.quemSomos.section.text}</p>
                </div>

                {window.innerWidth > 768 && (
                  <img className="ellipse" src={Ellipse} alt="Ellipse detail" />
                )}
              </div>
              <div className="people-pictures-img">
                <img
                  src={data.page.quemSomos.section.backgroundImage.sourceUrl}
                  alt="People"
                />
              </div>
            </Section>
            <Valores>
              {window.innerWidth <= 768 ? (
                <Slider {...settings}>
                  {data.page.quemSomos.carousel.map((item, i) => (
                    <div key={i} className="missao-slide">
                      <img src={item.icon.sourceUrl} alt="Icon" />
                      <p className="title">{item.title}</p>
                      <p className="text">{item.text}</p>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className="container d-flex align-items-start h-100 ">
                  {data.page.quemSomos.carousel.map((item, i) => (
                    <div
                      key={i}
                      className="missao-card"
                      style={{
                        flex: "1 1 0px",
                      }}
                    >
                      <img
                        src={item.icon.sourceUrl}
                        alt="Icon"
                        style={{ marginBottom: "32px" }}
                      />
                      <p className="title">{item.title}</p>
                      <p className="text">{item.text}</p>
                    </div>
                  ))}
                </div>
              )}
            </Valores>
            <TimeLine className={window.innerWidth === 1920 && "container"}>
              <div className="time-line-header">
                <h3>{data.page.quemSomos.history.title}</h3>
                <p className="text">{data.page.quemSomos.history.text}</p>
              </div>
              <Slider
                {...sliderTimeLIne}
                style={{
                  marginTop: `${window.innerWidth > 768 ? "90px" : "24px"}`,
                }}
              >
                {data.page.quemSomos.history.carousel.map((item, i) => (
                  <div key={i} className="card-time-line position-relative">
                    {i % 2 === 0 ? (
                      <div
                        className="card-time-line-img"
                        style={{
                          background: `url(${item.image.sourceUrl}) no-repeat center`,
                          backgroundSize: "cover",
                          height: "180px",
                          borderTopLeftRadius: "24px",
                          borderTopRightRadius: "24px",
                        }}
                      ></div>
                    ) : (
                      <div
                        key={i}
                        style={{
                          height: "180px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          padding: `${
                            window.innerWidth > 768 && "15px 15px 0"
                          }`,
                        }}
                      >
                        <p className="year">{item.year}</p>
                        <p className="text">{item.text}</p>
                      </div>
                    )}
                    <div
                      style={{
                        height: "3px",
                        background: "#004b8f",
                      }}
                    ></div>
                    <Dot position={i % 2 === 0 ? "top" : "bottom"}></Dot>
                    {i % 2 !== 0 ? (
                      <div
                        className="card-time-line-img"
                        style={{
                          background: `url(${item.image.sourceUrl}) no-repeat center`,
                          backgroundSize: "cover",
                          height: "180px",
                          borderBottomLeftRadius: "24px",
                          borderBottomRightRadius: "24px",
                        }}
                      ></div>
                    ) : (
                      <div
                        style={{
                          height: "180px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          padding: `${
                            window.innerWidth > 768 && "15px 15px 0"
                          }`,
                        }}
                      >
                        <p className="year">{item.year}</p>
                        <p className="text">{item.text}</p>
                      </div>
                    )}
                  </div>
                ))}
              </Slider>
            </TimeLine>
            <Achievements>
              <div className={window.innerWidth === 1920 && "container"}>
                <h4>{data.page.quemSomos.achievements.title}</h4>
                <p className="text">{data.page.quemSomos.achievements.text}</p>
                <Slider
                  {...sliderAchievments}
                  className="d-flex align-items-stretch"
                  style={{
                    marginTop: `${window.innerWidth > 768 ? "84px" : "44px"}`,
                  }}
                >
                  {data.page.quemSomos.achievements.carousel.map((item, i) => {
                    return (
                      <div key={i} className="achievment-card">
                        <div>
                          <p className="title">{item.title}</p>
                          <p className="text">{item.text}</p>
                        </div>
                        <div>
                          <div className="d-flex">
                            {Array.from(Array(item.awards), (e, i) => {
                              return <img src={Award} alt="Award icon" />;
                            })}
                          </div>
                          <img
                            src={Line}
                            style={{ marginTop: "26px" }}
                            alt="Line detail"
                          />
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </Achievements>
            <JoinUs data={data.page.quemSomos.joinUs} />
          </>
        );
      }}
    </Query>
  );
}

const Section = styled.section`
  display: flex;
  position: relative;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 97px;
    padding-right: 57px;

    h2 {
      font-family: Comfortaa;
      font-weight: bold;
      font-size: 34px;
      color: #a9b531;
      margin-bottom: 44px;
    }

    p {
      font-family: Nunito;
      color: #636872;
    }
  }

  .ellipse {
    position: absolute;
    bottom: -70px;
    left: 70px;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;

    .content-wrapper {
      padding: 44px 30px 0;
    }

    img {
      width: 100%;
    }
  }

  @media (max-width: 450px) {
    .content-wrapper {
      h2 {
        font-size: 24px;
      }
    }
  }

  @media (width: 1920px) {
    height: 580px;

    .content-wrapper {
      width: 80%;
    }

    .ellipse {
      left: 0;
    }

    .people-pictures-img {
      position: absolute;
      right: 0;
    }
  }
`;

const Valores = styled.div`
  background: #0fa752;
  padding: 97px 0 82px 0;
  color: white;

  .title {
    font-family: Comfortaa;
    font-weight: bold;
    font-size: 24px;
  }

  .text {
    font-family: Nunito;
  }

  .missao-card {
    flex: 1 1 0px;
    text-align: center;
    padding: 0 45px;

    img {
      display: block;
      margin: auto;
    }
  }

  .missao-slide {
    max-width: 360px;
    display: block !important;
    margin: auto;
    text-align: center;

    img {
      display: block;
      margin: 0 auto 32px;
    }

    .title {
      font-family: Comfortaa;
      font-weight: bold;
      font-size: 20px;
    }

    .text {
      font-family: Nunito;
    }
  }
  .slick-dots {
    bottom: -50px;
    li {
      button {
        width: 10px;
        height: 10px;
        padding: 0;
        border: 1px solid white;
        background: transparent;
        margin: auto;
        border-radius: 100%;

        &:before {
          opacity: 0;
        }
      }

      &.slick-active {
        button:before {
          opacity: 1;
          color: white;
          font-size: 11px;
          line-height: 11px;
        }
      }
    }
  }
`;

const TimeLine = styled.div`
  padding: 77px 96px 0 101px;
  height: 829px;

  h3 {
    font-family: Comfortaa;
    font-weight: bold;
    font-size: 34px;
    color: #004b8f;
  }

  .text {
    font-family: Nunito;
    color: #636872;
  }

  .card-time-line {
    .year {
      font-family: Nunito;
      font-weight: bold;
      font-size: 24px;
      color: #a9b531;
      text-align: center;
      margin-bottom: 0;
    }

    .text {
      font-family: Nunito;
      color: #636872;
      text-align: center;
    }

    img {
      margin: auto;
    }
  }

  .slick-dots {
    bottom: -150px;
    li {
      button {
        width: 10px;
        height: 10px;
        padding: 0;
        border: 1px solid #004b8f;
        background: transparent;
        margin: auto;
        border-radius: 100%;

        &:before {
          opacity: 0;
        }
      }

      &.slick-active {
        button:before {
          opacity: 1;
          color: #004b8f;
          font-size: 11px;
          line-height: 11px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 0;
    .time-line-header {
      padding: 44px 30px 0;
    }

    h3 {
      font-size: 24px;
    }
    .card-time-line {
      .text {
        padding: 0 30px;
      }

      img {
        height: 180px;
        width: 100%;
      }
    }

    .slick-dots {
      bottom: -80px;
    }
  }

  @media (max-width: 768px) {
    .card-time-line {
      img {
        width: unset;
      }
    }
  }

  @media (max-width: 450px) {
    .card-time-line {
      .card-time-line-img {
        margin: 0 15px;

        img {
          width: 100%;
        }
      }
    }
  }

  @media (width: 1920px) {
    .card-time-line img {
      // width: 100%;
    }
  }
`;

const Dot = styled.div`
  background: white;
  width: 16px;
  height: 16px;
  border: 3px solid #004b8f;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  ${(props) => props.position}: 172px;
`;

const Achievements = styled.div`
  background: #a9b531;
  padding: 74px 98px 168px;

  h4 {
    font-family: Comfortaa;
    font-weight: bold;
    font-size: 34px;
    color: #004b8f;
  }

  .text {
    font-family: Nunito;
    color: white;
  }

  .achievment-card {
    padding-right: 30px;
    height: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-family: Comfortaa;
      font-weight: bold;
      font-size: 24px;
      color: #004b8f;
    }

    .text {
      font-family: Nunito;
      color: white;
    }
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;

    > div {
      height: 100%;
    }
  }

  .slick-dots {
    bottom: -150px;
    li {
      button {
        width: 10px;
        height: 10px;
        padding: 0;
        border: 1px solid #004b8f;
        background: transparent;
        margin: auto;
        border-radius: 100%;

        &:before {
          opacity: 0;
        }
      }

      &.slick-active {
        button:before {
          opacity: 1;
          color: #004b8f;
          font-size: 11px;
          line-height: 11px;
        }
      }
    }
  }

  @media (max-width: 450px) {
    padding: 38px 30px 92px;

    h4 {
      font-size: 24px;
    }

    .slick-dots {
      bottom: -60px;
    }
  }
`;
