import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { Link } from "react-router-dom";

export default function Banner(props) {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Wrapper>
      {!props.static ? (
        <Slider {...settings}>
          {props.data.map((slide, i) => (
            <Content
              key={i}
              bg={slide.image.sourceUrl}
              bgMobile={slide.imageMobile.sourceUrl}
            >
              <div className="container">
                <div className="content-wrapper">
                  <h1>{slide.title}</h1>
                  <p>{slide.text}</p>
                  <Link to={slide.link}>
                    <button>{slide.button}</button>
                  </Link>
                </div>
              </div>
            </Content>
          ))}
        </Slider>
      ) : (
        <StaticBanner
          bg={props.infos.banner.imageDesk.sourceUrl}
          bgMobile={props.infos.banner.imageMobile.sourceUrl}
        >
          <div className="container">
            <div className="text-wrapper">
              <h1>{props.infos.banner.title}</h1>
              <p>{props.infos.banner.text}</p>
            </div>
          </div>
        </StaticBanner>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .slick-dots {
    bottom: 23px;

    li {
      button {
        width: 10px;
        height: 10px;
        padding: 0;
        border: 1px solid white;
        background: transparent;
        margin: auto;
        border-radius: 100%;

        &:before {
          opacity: 0;
        }
      }

      &.slick-active {
        button:before {
          opacity: 1;
          color: white;
          font-size: 11px;
          line-height: 11px;
        }
      }
    }
  }
`;

const Content = styled.div`
  background: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  height: 550px;

  .container .content-wrapper {
    position: absolute;
    top: 213px;
    color: #ffffff;
    max-width: 500px;

    h1 {
      font-family: Comfortaa;
      font-weight: bold;
      font-size: 34px;
    }

    p {
      font-family: Nunito;
    }

    button {
      background: #a9b531;
      border-radius: 44px;
      font-family: Nunito;
      font-weight: 800;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      color: #ffffff;
      border: none;
      padding: 16px 24px;
      box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.1),
        0px 41.7776px 33.4221px rgba(0, 0, 0, 0.07),
        0px 22.3363px 17.869px rgba(0, 0, 0, 0.06),
        0px 12.5216px 10.0172px rgba(0, 0, 0, 0.04),
        0px 6.6501px 5.32008px rgba(0, 0, 0, 0.03),
        0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    }
  }

  @media (max-width: 450px) {
    background: url(${(props) => props.bgMobile}) no-repeat;
    background-size: cover;
    height: 421px;

    position: relative;

    .container .content-wrapper {
      margin-left: 30px;
      top: 90px;

      h1 {
        font-size: 28px;
      }
    }
  }

  @media (max-width: 360px) {
    .container .content-wrapper {
      margin-left: 0;
    }
  }
`;

const StaticBanner = styled.div`
  background: url(${(props) => props.bg}) no-repeat;
  background-position: center;
  background-size: cover;
  height: 550px;
  position: relative;

  .text-wrapper {
    position: absolute;
    top: 213px;
    // margin-left: 97px;
    color: #ffffff;
    max-width: 470px;

    h1 {
      font-family: Comfortaa;
      font-weight: bold;
      font-size: 34px;
    }

    p {
      font-family: Nunito;
    }
  }

  @media (min-width: 1024px) {
    .text-wrapper {
      p {
        max-width: 365px;
      }
    }
  }

  @media (max-width: 450px) {
    background: url(${(props) => props.bgMobile}) no-repeat;
    background-size: cover;
    height: 260px;

    .text-wrapper {
      margin: 0 30px;
      bottom: unset;
      top: 110px;

      h1 {
        font-size: 24px;
      }
    }
  }

  @media (max-width: 360px) {
    .text-wrapper {
      margin: 0;
    }
  }
`;
