import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./components/Header";
import Home from "./pages/Home";
import QuemSomos from "./pages/QuemSomos";
import OndeEstamos from "./pages/OndeEstamos";
import TrabalheConosco from "./pages/TrabalheConosco";
import Footer from "./components/Footer";

import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const client = new ApolloClient({
  uri: "https://www.brasilcenter.com.br/wordpress/graphql",
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/quem-somos" exact>
            <QuemSomos />
          </Route>
          <Route path="/onde-estamos" exact>
            <OndeEstamos />
          </Route>
          <Route path="/trabalhe-conosco" exact>
            <TrabalheConosco />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </ApolloProvider>
  );
}

export default App;
