import React from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import styled from "styled-components";
import Slider from "react-slick";

import Header from "../components/Header";
import Banner from "../components/Banner";
import AwardsSection from "../components/AwardsSection";
import Map from "../components/Map";

import GreenDetail from "../images/green-detail.png";
import PinkDetail from "../images/pink-detail.png";
import Dots from "../images/dots.png";
import GrayElipse from "../images/gray-elipse.png";

export default function TrabalheConosco() {
  const MyQuery = gql`
    query MyQuery {
      page(id: "cGFnZToyMTc=") {
        id
        trabalheConosco {
          banner {
            imageDesk {
              sourceUrl
            }
            imageMobile {
              sourceUrl
            }
            title
            text
          }
          section {
            imageDesk {
              sourceUrl
            }
            imageMobile {
              sourceUrl
            }
            list {
              item
            }
          }
          beneficios {
            title
            text
            carousel {
              icon {
                sourceUrl
              }
              title
            }
          }
        }
      }
    }
  `;
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Query query={MyQuery}>
      {({ loading, error, data }) => {
        if (loading)
          return (
            <div
              style={{
                background: "#004B8F",
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
              }}
            >
              <p>Loading...</p>
            </div>
          );
        if (error) return <div>Error :(</div>;
        const pageData = data.page.trabalheConosco;
        const beneficionsInfo = pageData.beneficios.carousel;

        const beneficiosMobile = () => {
          let groups = [];
          let i,
            j,
            chunk = 4;
          for (i = 0, j = beneficionsInfo.length; i < j; i += chunk) {
            groups.push(beneficionsInfo.slice(i, i + chunk));
          }
          return groups;
        };

        return (
          <>
            <Header />
            <Banner infos={pageData} static />
            <Section>
              <div className="position-relative">
                <img
                  className="green-detail"
                  src={GreenDetail}
                  alt="green detail"
                />
                <img
                  className="pink-detail"
                  src={PinkDetail}
                  alt="pink detail"
                />
                {window.innerWidth > 450 ? (
                  <img
                    className="w-100"
                    src={pageData.section.imageDesk.sourceUrl}
                    alt=""
                    srcset=""
                  />
                ) : (
                  <img
                    className="w-100"
                    src={pageData.section.imageMobile.sourceUrl}
                    alt=""
                    srcset=""
                  />
                )}
              </div>
              <div className="list-wrapper container">
                <div className="section-list">
                  {pageData.section.list.map((item) => (
                    <div className="section-list-item">
                      <p>- {item.item}</p>
                    </div>
                  ))}
                  <img className="dots" src={Dots} alt="dots detail" />
                </div>
              </div>
            </Section>
            <AwardsSection />
            <Beneficios>
              <div className="container h-100">
                <div className="content-header-wrapper">
                  <p className="title">{pageData.beneficios.title}</p>
                  <p className="text">{pageData.beneficios.text}</p>
                </div>
                <div className="beneficios">
                  <img
                    className="gray-elipse"
                    src={GrayElipse}
                    alt="gray detail"
                  />
                  {window.innerWidth >= 768 ? (
                    pageData.beneficios.carousel.map((item, i) => (
                      <div className="beneficio-card">
                        <div>
                          <img src={item.icon.sourceUrl} alt="icon" />
                        </div>
                        <p>{item.title}</p>
                      </div>
                    ))
                  ) : (
                    <Slider {...settings}>
                      {beneficiosMobile().map((groups, i) => (
                        <div className="beneficio-cards-mobile">
                          {groups.map((item) => (
                            <div className="card-wrapper">
                              <div className="beneficio-card">
                                <div>
                                  <img src={item.icon.sourceUrl} alt="icon" />
                                </div>
                                <p>{item.title}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                    </Slider>
                  )}
                </div>
              </div>
            </Beneficios>
            <Map />
          </>
        );
      }}
    </Query>
  );
}

const Section = styled.div`
  padding: 84px 7.17%;
  overflow: hidden;

  .green-detail {
    position: absolute;
    top: 51.75px;
    left: -41px;
  }

  .pink-detail {
    position: absolute;
    right: -40px;
    bottom: -60px;
  }

  .list-wrapper {
    position: relative;
    padding: 64px 100px 0;
  }

  .section-list {
    // padding: 64px 100px;
    display: flex;
    flex-wrap: wrap;

    p {
      font-family: Nunito;
      color: #636872;
    }

    .section-list-item {
      width: 50%;
      padding: 0 10px;
    }

    .dots {
      position: absolute;
      top: 50%;
      right: 0;
    }
  }

  @media (max-width: 768px) {
    .list-wrapper {
      padding: 64px 7.32vw 0;

      .section-list {
        display: block;

        .section-list-item {
          width: 100%;
        }

        .dots {
          position: unset;
          display: block;
          margin-left: auto;
        }
      }
    }
  }

  @media (max-width: 450px) {
    padding: 0;

    .green-detail {
      height: 84px;
      top: 100px;
      left: -25px;
    }

    .pink-detail {
      right: -40px;
      bottom: -30px;
      width: 50%;
    }

    .list-wrapper {
      padding: 44px 8.33vw;
    }

    .section-list-item {
      width: 100%;
    }
  }
`;

const Beneficios = styled.div`
  background: #f7f7f7;
  padding: 112px 7.17%;
  position: relative;

  .title {
    font-family: Comfortaa;
    font-weight: bold;
    font-size: 34px;
    color: #004b8f;
    margin-bottom:0;
  }

  .text {
    font-family: Nunito;
    color: #636872;
    margin-bottom:44px;
  }

  .beneficios {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .beneficio-card {
      z-index: 2;
      background: white;
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 26px 0;
      margin-bottom: 44px;
      width: 100%;
      max-width: 270px;
      max-height: 88px;
      box-shadow: 0px 65px 41px rgba(0, 0, 0, 0.07),
        0px 27.1554px 17.1288px rgba(0, 0, 0, 0.0503198),
        0px 14.5186px 9.15789px rgba(0, 0, 0, 0.0417275),
        0px 8.13901px 5.13384px rgba(0, 0, 0, 0.035),
        0px 4.32257px 2.72654px rgba(0, 0, 0, 0.0282725),
        0px 1.79872px 1.13458px rgba(0, 0, 0, 0.0196802);

      p {
        font-family: Comfortaa;
        font-weight: bold;
        font-size: 18px;
        color: #636872;
        margin: 0;
        max-width: 184px;
      }

      img {
        margin-left: 19px;
        margin-right: 16px;
      }
    }

    .gray-elipse {
      position: absolute;
      left: 1.46vw;
      bottom: 0;
    }
  }

  @media (max-width: 450px) {
    padding: 44px 0;
    height:578px;

    .content-header-wrapper {
      padding: 0 30px 44px;
    }

    .title {
      font-size:24px;
    }

    .text {
      margin-bottom: 0;
    }

    .beneficios {
      display: block !important;
      height:100%;

      .gray-elipse {
        width:60%;
      }

      .slick-slider, .slick-list {
        height:100%;
      }

      .beneficio-cards-mobile {
        display: grid !important;
        grid-template-columns: 1fr 1fr !important;
        grid-template-rows: 150px 150px !important;

        .card-wrapper {
          padding: 0 10px 10px;
          height: 150px;

          .beneficio-card {
            height:100%;
            max-height:unset;
            display: flex !important;
            flex-direction: column;
            flex: 1 1 0px
            align-items: center;
            justify-content: space-around;
            margin:0;
            padding: 0;

            p {
              font-size: 16px;
              text-align: center;
            }
          }
        }
      }

      .slick-dots {
          bottom: 100px;
          li {
            button {
              width: 10px;
              height: 10px;
              padding: 0;
              border: 1px solid #004B8F;
              background: transparent;
              margin: auto;
              border-radius: 100%;

              &:before {
                opacity: 0;
              }
            }

            &.slick-active {
              button:before {
                opacity: 1;
                color: #004B8F;
                font-size: 11px;
                line-height: 11px;
              }
            }
          }
        }
    }
  }
`;
