import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Pin from "../images/new-pin.png";
import PinActive from "../images/pin-active.png";

export default function PinIcon(props) {
  const [isPinActive, setIsPinActive] = useState();
  const [currentPin, setCurrentPin] = useState();

  function printFullnameLocation(locationId) {
    switch (locationId) {
      case "ac":
        return "Acre";
        break;

      case "al":
        return "Alagoas";
        break;

      case "ap":
        return "Amapá";
        break;

      case "am":
        return "Amazonas";
        break;

      case "ba":
        return "Bahia";
        break;

      case "ce":
        return "Ceará";
        break;

      case "es":
        return "Espírito Santo";
        break;

      case "go":
        return "Goiás";
        break;

      case "ma":
        return "Maranhão";
        break;

      case "mt":
        return "Mato Grosso";
        break;

      case "ms":
        return "Mato Grosso do Sul";
        break;

      case "mg":
        return "Minas Gerais";
        break;

      case "pa":
        return "Pará";
        break;

      case "pb":
        return "Paraíba";
        break;

      case "pr":
        return "Paraná";
        break;

      case "pe":
        return "Pernambuco";
        break;

      case "pi":
        return "Piauí";
        break;

      case "rj":
        return "Rio de Janeiro";
        break;

      case "rn":
        return "Rio Grande do Norte";
        break;

      case "rs":
        return "Rio Grande do Sul";
        break;

      case "ro":
        return "Rondônia";
        break;

      case "rr":
        return "Roraima";
        break;

      case "sc":
        return "Santa Catarina";
        break;

      case "sp":
        return "São Paulo";
        break;

      case "se":
        return "Sergipe";
        break;

      case "to":
        return "Tocantins";
        break;

      case "df":
        return "Distrito Federal";
        break;

      default:
        return "Rio de Janeiro";
        break;
    }
  }

  useEffect(() => {
    if (props.activePin === props.city) {
      setIsPinActive(true);
    } else {
      setIsPinActive(false);
    }
  }, [props.activePin]);

  return (
    <PinWrapper
      className={`${props.city}
      " " 
      ${isPinActive ? "active" : ""}`}
      position={props.position}
    >
      <img
        id={`${props.city}`}
        className="pin"
        src={isPinActive ? PinActive : Pin}
        alt="pin"
        onClick={(e) => {
          props.setActivePin(e.currentTarget.id);
        }}
      />
      {isPinActive && <button>{printFullnameLocation(props.city)}</button>}
    </PinWrapper>
  );
}

const PinWrapper = styled.div`
  &.active {
    width: 109px;
    height: 109px;
  }

  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: ${(props) => props.position.normal.top};
  right: ${(props) => props.position.normal.right};
  z-index: 1;

  img {
    cursor: pointer;
  }

  &.active {
    top: ${(props) => props.position.active.top} !important;
    right: ${(props) => props.position.active.right} !important;
    z-index: 2 !important;
  }

  button {
    position: absolute;
    bottom: -20px;
    border: none;
    background: #a9b531;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
      0px 0px 15px rgba(169, 181, 49, 0.35);
    border-radius: 44px;
    font-family: Nunito;
    font-weight: 800;
    font-size: 14px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #ffffff;
    white-space: nowrap;
    padding: 13.5px 24px;
  }
`;
