import React from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import styled from "styled-components";
import Slider from "react-slick";
import Elipse from "../images/elipse-bottom.png";

export default function AwardsSection(props) {
  const MyQuery = gql`
    query MyQuery {
      components {
        nodes {
          awards {
            title
            numbers {
              number
              text
            }
          }
        }
      }
    }
  `;
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Query query={MyQuery}>
      {({ loading, error, data }) => {
        if (loading) return <div>Loading...</div>;
        if (error) return <div>Error :(</div>;

        const awards = data.components.nodes.filter(
          (node) => node.awards.numbers && node.awards.numbers.length > 0
        );
        const awardsInfo = awards[0].awards.numbers;
        const title = awards[0].awards.title;

        return (
          <Wrapper bg={Elipse}>
            <div className="container">
              <div className="award-content">
                <p className="title">{title}</p>
                {window.innerWidth <= 768 ? (
                  <Slider {...settings}>
                    {awardsInfo.map((item, i) => (
                      <AwardWrapperMobile key={i}>
                        <p className="number">{item.number}</p>
                        <p className="text">{item.text}</p>
                      </AwardWrapperMobile>
                    ))}
                  </Slider>
                ) : (
                  <AwardWrapper>
                    {awardsInfo.map((item, i) => (
                      <div key={i} className="award-item">
                        <p className="award-number">{item.number}</p>
                        <p>{item.text}</p>
                      </div>
                    ))}
                  </AwardWrapper>
                )}
              </div>
            </div>
          </Wrapper>
        );
      }}
    </Query>
  );
}

const Wrapper = styled.div`
  background-color: #a9b531;
  height: 168px;

  .number,
  .award-item,
  .text,
  .award-number {
    font-family: Nunito;
  }

  @media (min-width: 1024px) {
    .award-content {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .container,
  .row,
  .award-content {
    height: 100%;
  }

  .container {
    .title {
      font-family: Comfortaa;
      font-size: 24px;
      font-weight: bold;
      color: #004b8f;
      margin: 0;
    }

    .award-item {
      color: white;
      text-align: center;
      background: #9faf37;
      border-radius: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 246px;
      height: 246px;

      .award-number {
        font-weight: bold;
        font-size: 44px;
        line-height: 1;
        margin: 0;
      }
    }
  }

  @media (max-width: 768px) {
    & {
      height: 316px;
      background: #a9b531 url("${(props) => props.bg}") no-repeat bottom;
      background-size: contain;
    }
    .container {
      padding-top: 50px;

      .award-content {
        .title {
          margin: 0 auto ${window.innerWidth > 450 ? "10px" : "0"};
          font-size: 24px;
          max-width: 270px;
          text-align: center;
        }

        .number {
          font-family: Nunito;
          font-weight: bold;
          font-size: 48px;
        }

        .text {
          font-family: Comfortaa;
          font-weight: bold;
          font-size: 20px;
        }

        .slick-dots {
          bottom: -50px;
          li {
            button {
              width: 10px;
              height: 10px;
              padding: 0;
              border: 1px solid white;
              background: transparent;
              margin: auto;
              border-radius: 100%;

              &:before {
                opacity: 0;
              }
            }

            &.slick-active {
              button:before {
                opacity: 1;
                color: white;
                font-size: 11px;
                line-height: 11px;
              }
            }
          }
        }
      }

      .numbersWrappers {
        div {
          background: none;
        }
      }
    }
  }
`;

const AwardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const AwardWrapperMobile = styled.div`
  color: white;
  text-align: center;

  p {
    margin: 0;
  }
`;
