import React, { useState, useEffect } from "react";
import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import Slider from "react-slick";

import Locations from "../locations.js";
import PinIcon from "./Pin";
import Dropdown from "./Dropdown";

import bgImg from "../images/bg-map.png";
import HouseIcon from "../images/house.png";
import LineCard from "../images/line-card.png";
import WheelChair from "../images/wheelchair.png";
import Accessibility from "../images/accessibility.png";

export default function Map(props) {
  const MyQuery = gql`
    query MyQuery {
      components {
        nodes {
          Map {
            offices {
              adress
              cityName
              fieldGroupName
              homeOffice
              jobs
              linkMap
              linkRegister
              pcd
              state
            }
          }
        }
      }
    }
  `;
  const [activePin, setActivePin] = useState("rj");
  const [activeLocation, setActiveLocation] = useState({
    cityName: "São Paulo (SP)",
    jobs: "160",
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Query query={MyQuery}>
      {({ loading, error, data }) => {
        if (loading) return <div>Loading...</div>;
        if (error) return <div>Error :(</div>;

        const clearData = data.components.nodes.filter(
          (node) => node.Map.offices && node.Map.offices.length > 0
        );
        const offices = clearData[0].Map.offices;
        const registeredLocations = offices.map((office) => office["state"]);
        const uniqueLocations = [...new Set(registeredLocations)];
        const filteredPins = Locations.filter((location) => {
          for (let index = 0; index < uniqueLocations.length; index++) {
            if (location.city == uniqueLocations[index].toLowerCase()) {
              return true;
            }
          }
          return false;
        });

        let activeLocations = offices.filter(
          (office) => office["state"].toLowerCase() === activePin
        );
        const settings = {
          dots: true,
          arrows: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        };

        return (
          <Wrapper
            bg={bgImg}
            id="map-wrapper"
            onClick={(e) => {
              !e.target.classList.contains("pin") &&
                e.target.nodeName !== "BUTTON" &&
                window.innerWidth > 768 &&
                setActivePin("rj");
            }}
          >
            {window.innerWidth > 768 &&
              filteredPins.map((location, i) => (
                <PinIcon
                  key={i}
                  city={location.city}
                  position={location.position}
                  setActivePin={setActivePin}
                  activePin={activePin}
                />
              ))}
            <div className={window.innerWidth > 768 && "container"}>
              <div className="card">
                <div className="p-sm">
                  <p className="title">Junte-se a nós</p>
                  <p className="text">
                    Selecione um dos estados em que estamos presentes e confira
                    as oportunidades de iniciar sua carreira de sucesso.
                  </p>
                  {window.innerWidth <= 768 && (
                    <Dropdown
                      items={uniqueLocations}
                      setActivePin={setActivePin}
                    />
                  )}
                  <img
                    style={{ marginBottom: "32px", width: "100%" }}
                    src={LineCard}
                    alt="Line detail"
                  />
                </div>
                <Slider {...settings}>
                  {activeLocations.map((activeLocation) => {
                    return (
                      <div className="location-item">
                        <p className="city">{activeLocation.cityName}</p>
                        <p className="jobs">
                          <span>{activeLocation.jobs} </span>vagas disponíveis
                        </p>
						{activeLocation.pcd &&
							<div className="d-flex">
							  <div>
								<img src={WheelChair} alt="wheelchair icon" />
							  </div>
							  <p className="txt-sm-white">
								Nesta região, também há vagas PCD.{" "}
								<a
								  href=""
								  onClick={(e) => {
									e.preventDefault();
									handleShow();
								  }}
								>
								  Saiba mais.
								</a>
							  </p>
							</div>
						}
						{activeLocation.homeOffice &&
                        <div className="d-flex">
                          <div>
                            <img src={HouseIcon} alt="House icon" />
                          </div>
                          <p className="txt-sm-white">
                            Nesta região, também há vagas home office
                          </p>
                        </div>
						}
                        <div className="mt-auto">
                          <a href="https://vemprabcc.gupy.io/"><button className="cta">Cadastrar</button></a>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
            <Modal show={show} onHide={handleClose} closeButton>
              <Modal.Header closeButton>
                <div className="modalPCD">
                  <img
                    src={Accessibility}
                    style={{ marginBottom: "32px" }}
                    alt="Accessibility icon"
                  />
                  <p
                    style={{
                      fontFamily: "Comfortaa",
                      fontWeight: "bold",
                      fontSize: "20px",
                      color: "#636872",
                    }}
                  >
                    Pessoas com deficiência têm acesso a um mundo de
                    possibilidades
                  </p>
                  <p
                    style={{
                      fontFamily: "Nunito",
                      color: "#636872",
                    }}
                  >
                    Venha trabalhar em um ambiente acolhedor e inclusivo, onde
                    você irá aprender novas habilidades, agregar experiências e
                    se desenvolver como profissional.
                  </p>
                </div>
              </Modal.Header>
            </Modal>
          </Wrapper>
        );
      }}
    </Query>
  );
}

const Wrapper = styled.div`
  background: #013977 url(${(props) => props.bg}) no-repeat top right;
  height: 651px;
  padding: 76px 62px;
  position: relative;

  .card {
    background: linear-gradient(44.45deg, #004b8f 44.74%, #27f4af 174.37%);
    box-shadow: 0px 100px 105px rgba(0, 0, 0, 0.09),
      0px 41.7776px 43.8665px rgba(0, 0, 0, 0.0646969),
      0px 22.3363px 23.4531px rgba(0, 0, 0, 0.0536497),
      0px 12.5216px 13.1476px rgba(0, 0, 0, 0.045),
      0px 6.6501px 6.98261px rgba(0, 0, 0, 0.0363503),
      0px 2.76726px 2.90562px rgba(0, 0, 0, 0.0253031);
    border-radius: 8px;
    width: 323px;
    // width: 533px;
    // height: auto;
    // height: 499px;
    height: 640px;
    // padding: 40px 32px;
    padding: 0px 32px;
    position: relative;
    margin-top: -72px;

    .title {
      font-family: Comfortaa;
      font-weight: bold;
      font-size: 34px;
      color: #ffffff;
    }

    .text {
      font-family: Nunito;
      color: #ffffff;
    }

    .city {
      font-family: Comfortaa;
      font-weight: bold;
      font-size: 24px;
      color: #ffffff;
      margin-bottom: 0;
      line-height: 1em;
    }

    .jobs {
      font-family: Nunito;
      font-weight: bold;
      color: #a9b531;
      font-size: 24px;
      margin-bottom: 0;
      span {
        font-size: 56px;
      }
    }

    .txt-sm-white {
      font-family: Nunito;
      color: #ffffff;
      margin-left: 16px;

      a {
        font-weight: bold;
        color: #a9b531;
        text-decoration: underline;
      }
    }

    .cta {
      background: #ffffff;
      box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.1),
        0px 41.7776px 33.4221px rgba(0, 0, 0, 0.07),
        0px 22.3363px 17.869px rgba(0, 0, 0, 0.06),
        0px 12.5216px 10.0172px rgba(0, 0, 0, 0.04),
        0px 6.6501px 5.32008px rgba(0, 0, 0, 0.03),
        0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
      border: none;
      border-radius: 44px;
      font-family: Nunito;
      font-size: 14px;
      font-weight: 800;
      color: #004b8f;
      padding: 13.5px 24px;
      text-transform: uppercase;
    }

    .slick-dots {
      bottom: -60px;
      li {
        button {
          width: 10px;
          height: 10px;
          padding: 0;
          border: 1px solid white;
          background: transparent;
          margin: auto;
          border-radius: 100%;

          &:before {
            opacity: 0;
          }
        }

        &.slick-active {
          button:before {
            opacity: 1;
            color: white;
            font-size: 11px;
            line-height: 11px;
          }
        }
      }
    }
  }

  @media (min-width: 1380px) {
    .card {
      width: 433px;
      height: 470px;
    }
  }

  @media (max-width: 768px) {
    padding: 0;
    height: auto;

    .card {
      width: 100%;
      height: auto;
      padding: 0;

      .p-sm {
        padding: 44px 30px 0;
      }

      .location-item {
        padding: 0 30px 60px;
      }

      .slick-slider {
        margin-bottom: 20px;

        .slick-dots {
          bottom: 0;
        }
      }
    }
  }

  @media (width: 320px) {
    .card {
      .location-item {
        padding: 0 15px 60px;
      }

      .title {
        font-size: 31px;
      }
    }
  }
  @media (max-width: 360px) {
    .card {
      .jobs span {
        font-size: 48px;
      }
    }
  }
`;
