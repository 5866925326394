const Locations = [
  {
    city: "ce",
    position: {
      normal: { top: "90.5px", right: "120px" },
      active: { top: "56px", right: "80px" },
    },
  },
  {
    city: "pi",
    position: {
      normal: { top: "92.5px", right: "165px" },
      active: { top: "58px", right: "125px" },
    },
  },
  {
    city: "pb",
    position: {
      normal: { top: "114.5px", right: "65px" },
      active: { top: "80px", right: "25px" },
    },
  },
  {
    city: "al",
    position: {
      normal: { top: "154px", right: "60px" },
      active: { top: "119px", right: "20px" },
    },
  },
  {
    city: "se",
    position: {
      normal: { top: "175px", right: "67px" },
      active: { top: "140px", right: "27px" },
    },
  },
  {
    city: "mg",
    position: {
      normal: { top: "320px", right: "179px" },
      active: { top: "285px", right: "140px" },
    },
  },
  {
    city: "pe",
    position: {
      normal: { top: "134px", right: "94px" },
      active: { top: "99px", right: "54px" },
    },
  },
  {
    city: "go",
    position: {
      normal: { top: "295px", right: "300px" },
      active: { top: "260px", right: "260px" },
    },
  },
  {
    city: "es",
    position: {
      normal: { top: "330px", right: "103px" },
      active: { top: "295px", right: "64px" },
    },
  },
  {
    city: "rj",
    position: {
      normal: { top: "385px", right: "130px" },
      active: { top: "350px", right: "90px" },
    },
  },
  {
    city: "sp",
    position: {
      normal: { top: "390px", right: "274px" },
      active: { top: "355px", right: "234px" },
    },
  },
  {
    city: "ac",
    position: {
      normal: { top: "160px", right: "680px" },
      active: { top: "125px", right: "640px" },
    },
  },
  {
    city: "ap",
    position: {
      normal: { top: "5px", right: "360px" },
      active: { top: "-30px", right: "320px" },
    },
  },
  {
    city: "am",
    position: {
      normal: { top: "94px", right: "37.33%" },
      active: { top: "360px", right: "17%" },
    },
  },
  {
    city: "ba",
    position: {
      normal: { top: "199px", right: "139px" },
      active: { top: "164px", right: "99px" },
    },
  },
  {
    city: "df",
    position: {
      normal: { top: "275px", right: "265px" },
      active: { top: "240px", right: "225px" },
    },
  },
  {
    city: "mt",
    position: {
      normal: { top: "235px", right: "390px" },
      active: { top: "200px", right: "350px" },
    },
  },
  {
    city: "ma",
    position: {
      normal: { top: "89.5px", right: "245px" },
      active: { top: "55px", right: "205px" },
    },
  },
  {
    city: "ms",
    position: {
      normal: { top: "370px", right: "394px" },
      active: { top: "335px", right: "354px" },
    },
  },
  {
    city: "pa",
    position: {
      normal: { top: "89.5px", right: "390px" },
      active: { top: "55px", right: "350px" },
    },
  },
  {
    city: "pr",
    position: {
      normal: { top: "450px", right: "307px" },
      active: { top: "415px", right: "267px" },
    },
  },
  {
    city: "sc",
    position: {
      normal: { top: "525px", right: "307px" },
      active: { top: "490px", right: "267px" },
    },
  },
  {
    city: "rs",
    position: {
      normal: { top: "581.5px", right: "347px" },
      active: { top: "547px", right: "307px" },
    },
  },
  {
    city: "rn",
    position: {
      normal: { top: "90.5px", right: "80px" },
      active: { top: "56px", right: "40px" },
    },
  },
  {
    city: "rr",
    position: {
      normal: { top: "5px", right: "520px" },
      active: { top: "-30px", right: "480px" },
    },
  },
  {
    city: "ro",
    position: {
      normal: { top: "170px", right: "570px" },
      active: { top: "135px", right: "530px" },
    },
  },
  {
    city: "to",
    position: {
      normal: { top: "169px", right: "290px" },
      active: { top: "134px", right: "250px" },
    },
  },
];

export default Locations;
